import { CloudinaryImage } from "@/lib/constants/cloudinary";
import RoomInfo, { RoomInfoProps } from "./RoomInfo";
import { useTranslation } from "@/lib/i18n/client";
import Text from "../Text";
import Badges, { BadgesProps } from "./Badges";
import { Coordinates } from "@/lib/types/Map";
import { classNames } from "@/lib/utils/general";
import { getUrl, Urls } from "@/lib/constants/urls";
import { useQueryParams } from "@/lib/hooks/useQueryParams";
import SimpleImageSwiper from "../ImageSwiper/SimpleImageSwiper";
import { GoogleTagManagerEvents } from "@/lib/utils/googleTagEvents";

export type RoomProps = {
    isFeaturedRoom?: boolean;
    roomId: string;
    rating: number;
    category: string;
    pricePerMonth: number;
    originalPricePerMonth?: number;
    currency: string;
    totalStay: number;
    totalOriginal: number;
    title?: string;
    address: string;
    className?: string;
    images: CloudinaryImage[];
    slug: string;
    fixedSlug: string;
    location: Coordinates;
    disableImageSwipe?: boolean;
    companyId?: string;
} & RoomInfoProps &
    BadgesProps;

const Room = ({
                  roomId,
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  rating,
                  isInstantBooking,
                  isFeatured,
                  isSuperHost,
                  pricePerMonth,
                  originalPricePerMonth = undefined,
                  currency,
                  totalStay,
                  totalOriginal,
                  address,
                  lang,
                  images,
                  category,
                  className = "",
                  numGuests,
                  numBedrooms,
                  numBeds,
                  numBaths,
                  disableImageSwipe = false,
                  isFeaturedRoom = false,
                  companyId,
              }: RoomProps) => {
    const { t } = useTranslation(lang, "search");
    // const itemBottomCss = "flex flex-row items-baseline  justify-between p-3";
    const currencyStyle: Intl.NumberFormatOptions = { style: "currency", currency: "EUR", minimumFractionDigits: 0 };
    const originalCurrencyStyle: Intl.NumberFormatOptions = {
        style: "currency",
        currency: currency ? currency : "EUR",
        minimumFractionDigits: 0,
    };

    const PricewithCurrencySymbol = Math.round(pricePerMonth / 100).toLocaleString("de-DE", currencyStyle);
    const PricewithoutCurrencySymbol = Math.round(pricePerMonth / 100).toLocaleString("de-DE");
    const CurrencySymbol = PricewithCurrencySymbol.charAt(PricewithCurrencySymbol.length - 1);

    const OriginalPricewithCurrencySymbol = Math.round(pricePerMonth / 100).toLocaleString("de-DE", originalCurrencyStyle);
    // const OriginalPricewithoutCurrencySymbol = Math.round(pricePerMonth / 100).toLocaleString("de-DE");
    const OriginalCurrencySymbol = PricewithCurrencySymbol.charAt(OriginalPricewithCurrencySymbol.length - 1);


    const searchUrlParams = useQueryParams();

    const openRoomDetails = (e: React.MouseEvent<Element, MouseEvent>) => {
        const target = e?.target as HTMLButtonElement;
        if (target.className === "swiper-button-next" || target.className === "swiper-button-prev") {
            return;
        }
        GoogleTagManagerEvents.clickOnViewRoomDetailsCard(
            lang,
            isInstantBooking ? "instant_request" : "booking_request",
            Math.round(pricePerMonth / 100),
            numGuests,
            isFeaturedRoom ? "homepage" : "search_result_page",
        );
        searchUrlParams.set("roomId", roomId);
        window.open(getUrl(Urls.room.index, lang, {}, searchUrlParams.toString()), "_blank");
    };

    return (
        <div
            className={classNames(
                "rounded-md grow flex flex-col flex-cols-10 h-full overflow-hidden shadow-md cursor-pointer aspect-[5/6]",
                ...className.split(" "),
            )}
            onClick={openRoomDetails}
            onKeyDown={() => {
            }}
        >
            {/* START Images and Badges */}
            <div className="w-full h-3/5">
                <div className="relative">
                    <div className="absolute p-4 z-5">
                        <Badges
                            lang={lang}
                            isInstantBooking={isInstantBooking}
                            isFeatured={isFeatured}
                            isSuperHost={isSuperHost}
                        />
                    </div>
                </div>
                <SimpleImageSwiper images={images} useOldCloudinary={true} singleImage={disableImageSwipe} />
            </div>
            {/* END Images and Badges */}
            {/* START Main section */}
            <div className="w-full  h-2/5 bg-white">
                <div className="w-full h-3/5">
                    <div
                        className="flex flex-row justify-between align-center max-w-[350px] p-3 whitespace-break-spaces">
                        <div className="h-0">
                            <Text as="span" className="text-sm font-bold line-clamp-1" title={category}>
                                {category}
                            </Text>
                        </div>
                    </div>
                    <div className="flex w-full justify-between p-3 ">
                        <Text as="span" className="text-xs self-start text-gray-500 max-w-[250px]  line-clamp-1">
                            {address}
                        </Text>
                        <Text as="span" className="text-xs self-end">
                            ID: <b>{roomId}</b>
                        </Text>
                    </div>

                    <div className=" text-s flex flex-col content-center  h-6  justify-between p-3">
                        <RoomInfo
                            lang={lang}
                            numBaths={numBaths}
                            numBedrooms={numBedrooms}
                            numBeds={numBeds}
                            numGuests={numGuests}
                        />
                    </div>
                </div>
                {/* START Footer */}
                <div className="w-full h-2/5 bg-gray-100 ">
                    {/* Full Footer */}
                    <div className="h-full flex flex-col justify-end ">
                        {/* First block 1/5 of the full footer */}
                        <div className="flex items-baseline">
                            <div className="w-[13px] "></div>
                            {originalPricePerMonth && !totalStay && (
                                <div className="flex">
                                    <Text as="span" className="text-sm text-gray-500 line-through">
                                        {lang === "en"
                                            ? `${CurrencySymbol} ${Math.round(originalPricePerMonth / 100).toLocaleString("de-DE")}`
                                            : Math.round(originalPricePerMonth / 100).toLocaleString("de-DE", currencyStyle)}
                                    </Text>
                                    <Text className="px-2  text-sm text-green-500">
                                        {Math.round((originalPricePerMonth -pricePerMonth)/ (originalPricePerMonth /100)) }% discount
                                    </Text>
                                </div>
                            )}
                        </div>

                        {/* Second block 1/5 of the full footer */}
                        <div className="flex  items-baseline  justify-between">

                            {!totalStay && (
                                <div className="flex items-baseline px-3 ">
                                    {companyId === process.env.NEXT_PUBLIC_MYROOM24_COMPANY && (
                                        <Text as="span" className="text-xs mr-2">
                                            {t("room.from")}
                                        </Text>
                                    )}
                                    <Text as="span" className="text-lg font-bold">
                                        {lang === "en"
                                            ? `${OriginalCurrencySymbol} ${PricewithoutCurrencySymbol}`
                                            : PricewithCurrencySymbol}
                                    </Text>
                                    <Text as="span" className="text-xs">
                                        /{t("room.month")}
                                    </Text>
                                </div>
                            )}

                            {totalStay && (
                                <div className="flex items-start px-3 ">
                                    <Text as="span" className="text-lg font-bold ">
                                        {lang === "en"
                                            ? `${CurrencySymbol} ${Math.round(totalStay).toLocaleString("de-DE")}`
                                            : Math.round(totalStay / 100).toLocaleString("de-DE", currencyStyle)}
                                    </Text>
                                    <Text as="span" className="text-xs px-5 py-3">
                                        /{t("room.totalStay")}
                                    </Text>
                                </div>
                            )}

                            {totalOriginal && (
                                <div className="flex items-start px-3 py-1 justify-between">
                                    <Text as="span" className="text-lg font-bold px-3 py-0">
                                        {lang === "en"
                                            ? `${currency} ${Math.round(totalOriginal).toLocaleString("de-DE")}`
                                            : Math.round(totalOriginal / 100).toLocaleString("de-DE", currencyStyle)}
                                    </Text>
                                    <Text as="span" className="text-xs">
                                        /{t("room.totalStay")}
                                    </Text>
                                </div>
                            )}
                        </div>

                        {/* Third block 1/5 of the full footer */}
                        <div className="flex  items-start  justify-between ">
                            <div className="flex  px-3 py-1 ">
                                <div className="flex items-start ">
                                    <Text as="span" className="text-xs text-gray-500">
                                        {t("room.feesAndTaxes")}
                                    </Text>
                                </div>
                            </div>
                            {/* Conditional rendering based on category */}
                            {category && category.charAt(0) === "H" && (
                                <div className="flex items-end">
                                    <div className="flex items-start py-1 pe-3">
                                        <Text as="span" className="flex text-xs">
                                            {t("room.availableFrom")} <b>{t("room.availableFrom1")}</b>
                                        </Text>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Room;
