import { useTranslation } from "@/lib/i18n/client";
import { LanguageParams } from "@/lib/types/PageProps";
import { faBath, faBed, faDrawSquare, faUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import Text from "../Text";

export type RoomInfoProps = {
    numGuests: number;
    numBedrooms: number;
    numBeds: number;
    numBaths: number;
    isMobile?: boolean;
} & LanguageParams;

type RoomFeature = {
    title: string;
    className?: string;
    isMobile?: boolean;
} & Pick<FontAwesomeIconProps, "icon">;

const RoomFeature = ({ title, icon, className, isMobile = false }: RoomFeature) => {
    return (
        <Text as="span" className={`${isMobile ? "text-s" : "text-xs"} text-black ${className}`}>
            <FontAwesomeIcon icon={icon} className="pr-1" />
            {title}
        </Text>
    );
};

const RoomInfo = ({ numGuests, numBedrooms, numBeds, numBaths, isMobile = false, lang }: RoomInfoProps) => {
    const { t } = useTranslation(lang, "search");

    return (
        <div className={`h-full flex flex-row ${isMobile ? "gap-4" : "justify-between"} items-center`}>
            <RoomFeature icon={faUser} title={isMobile ? numGuests.toString() : t("room.guests", { count: numGuests })} isMobile={isMobile} />
            <RoomFeature icon={faDrawSquare} title={isMobile ? numBedrooms.toString() : t("room.bedrooms", { count: numBedrooms })} isMobile={isMobile} />
            <RoomFeature icon={faBed} title={isMobile ? numBeds.toString() : t("room.beds", { count: numBeds })} isMobile={isMobile} />
            <RoomFeature icon={faBath} title={isMobile ? numBaths.toString() : t("room.baths", { count: numBaths })} isMobile={isMobile} />
        </div>
    );
};

export default RoomInfo;
